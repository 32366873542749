<link rel="preconnect" href="https://GB0QQV5RQM-dsn.algolia.net" crossorigin />
<div.search key="container"/>

style {
  .search {
    padding: 0 1.5em;
    width: 196px;
  }

  .search > button {
    margin: 0;
  }

  @media (max-width: 768px) {
    .search {
      padding: 1.5em;
      padding-left: 0;
      display: flex;
    }

    .search.search.search .DocSearch-Button {
      background: transparent;
      padding: 0 0.5em;
      border-radius: 0;
      box-shadow: none;
      outline: unset;
      height: auto;

    }

    .search.search .DocSearch-Button:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    .search .DocSearch-Button-Container {
      width: 1rem;
      height: 1rem;
    }
  }

  @media (max-width:45em) {
    .search {
      padding: 0;
      display: flex;
    }
  }
}