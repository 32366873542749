import ghGot from "gh-got";

<home-feature-block colors=["#fff"]>
    <@title.home-community__title text="Join the Community" anchorName="community"/>
    <@content>
        <p>Need help? Want to contribute? Get involved in the Marko Community!</p>
    </@content>
    <@visual>
        <div.home-community__locations>
            <div.home-community__location>
                <a.home-community__logo href="https://stackoverflow.com/questions/tagged/marko">
                    <img src="../../../../logos/stackoverflow.svg" alt="StackOverflow"/>
                </a>
                <span>Ask & answer StackOverflow questions with the <a href="https://stackoverflow.com/questions/tagged/marko">marko tag</a></span>
            </div>
            <div.home-community__location>
                <a.home-community__logo href="https://discord.gg/marko">
                    <img src="../../../../logos/discord.svg" alt="Discord"/>
                </a>
                <span>Hang out in our <a href="https://discord.gg/marko">Discord server</a>, ask questions, & discuss project direction</span>
            </div>
            <div.home-community__location>
                <a.home-community__logo href="https://twitter.com/search?q=%23markojs%20OR%20%40markodevteam&f=live">
                    <img src="../../../../logos/twitter.svg" alt="Twitter"/>
                </a>
                <span>Tweet to <a href="https://twitter.com/MarkoDevTeam">@MarkoDevTeam</a> or with the <a href="https://twitter.com/search?q=%23markojs%20OR%20%40markodevteam&f=live">#markojs</a> hashtag</span>
            </div>
            <div.home-community__location>
                <a.home-community__logo href="https://github.com/marko-js/marko">
                    <img src="../../../../logos/github.svg" alt="GitHub"/>
                </a>
                <span>Browse the code, open issues, & make pull requests on the <a href="https://github.com/marko-js/marko">GitHub repo</a></span>
            </div>
        </div>
    </@visual>
    <@breakout>
        <div.home-community__contributors>
            <await(ghGot('/repos/marko-js/marko/contributors?per_page=100'))>
                <@then|{ body }|>
                    <for|contributor| of=body>
                        <a href=contributor.html_url><img src=`${contributor.avatar_url}&s=64` alt=contributor.login loading="lazy"/></a>
                    </for>
                </@then>
                <@catch>
                    
                </@catch>
            </await>
        </div>
    </@breakout>
</home-feature-block>

style {
    .home-community__title {
        color:#4927a9;
    }
    .home-community__locations {
        display:flex;
        padding-top:1em;
    }
    .home-community__location {
        display:flex;
        flex-direction: column;
        align-items: center;
        padding:1em;
        width:25%;
    }
    .home-community__logo{
        display:flex;
        justify-content: center;
        align-items: center;
        height:5em;
    }
    .home-community__logo img {
        width:3em;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
    }
     .home-community__logo img:hover {
      animation-name: bounce;
    }
     .home-community__logo img:active {
      opacity: 0.8;
    }
    .home-community__contributors {
        line-height: 0;
        text-align: center;
        margin-top: 2em;
        position: relative;
    }
    .home-community__contributors img {
        border-radius: 4px;
        width:5%;
        position:relative;
        transition: transform 200ms;
        border:0.1vw solid #fff;
        background: #fff;
        box-sizing:border-box;
        transition: opacity 200ms;
    }
    @media (max-width: 50em) {
        .home-community__locations {
            flex-wrap: wrap;
        }
        .home-community__location {
            width:50%;
        }
    }
    @media (min-width:45em) {
        .home-community__contributors img {
            width:4%;
        }
    }
    @media (min-width:75em) {
        .home-community__contributors img {
            width:3%;
        }
        .home-community__contributors img:last-child {
            display: none;
        }
    }
}
