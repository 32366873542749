import getAnchorName from './getAnchorName';

$ var className = input['class'];
$ var text = input.text;
$ var anchorName = input.anchorName || getAnchorName(text, out);

style {
    .heading a.anchor {
        display: inline-block;
        cursor: pointer;
        position: absolute;
        text-align: center;
        margin-left: -1.1em;
        padding: 0 0.3em;
        opacity: 0;
        color: inherit;
    }

    .heading:hover a.anchor {
        opacity: 0.5;
    }

    .heading.header-link {
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        line-height: 0.5em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1em;
    }

    .heading .header-link:before {
        content: "#";
        font-weight: 600;
    }
}

<${input.tag} class=['heading', className]>
    <a name=anchorName class="anchor" href=`#${anchorName}`>
        <span class="header-link"></span>
    </a>
    <if(text)>
        ${text}
    </if>
    <else>
        <${input.renderBody}/>
    </else>
</>
