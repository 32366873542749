style {
  .discord-button {
    position: fixed;
    bottom:0.25em;
    right: 1em;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }
  .discord-button:hover {
    animation-name: bounce;
  }
  .discord-button:active {
    opacity: 0.8;
  }
  .discord-button img {
    height: 3.5em;
  }
  @keyframes bounce {
    0% {
      transform: translateY(0)
    }
    10% {
      transform: translateY(0) scale(1.1, 0.9);
    }
    30% {
      transform: translateY(-20px) scale(0.95, 1.05);
    }
    40% {
      transform: translateY(0) scale(1.05, 0.95);
    }
    50% {
      transform: translateY(-10px) scale(0.98, 1.02);
    }
    70% {
      transform: translateY(0) scale(1.02, 0.98);
    }
    100% {
      transform: translateY(0)
    }
  }
}

<a.discord-button href="https://discord.gg/marko" target="_blank">
  <img src="../../logos/discord.svg" alt="Chat in Marko's Discord Server"/>
</a>