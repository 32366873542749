.site-footer {
    width:100%;
    color:#585a5c;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.site-footer a {
    color:#585a5c;
    height: 5em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.site-footer a img {
    position: relative;
    margin-right:0.3em;
}

.site-footer a.openjsf img {
    height:2em;
}

.site-footer a.ebay img {
    height:1em;
    top:0.1em;
}

.site-footer a.osi img {
    height:1.5em;
}


.site-footer a .bold {
    font-weight: 600;
}
.site-footer a .light {
    font-weight: 200;
}

.site-footer .separator {
    color:#dbdddf;
    font-size:3em;
    font-weight: 100;
    margin:0 0.2em;
    position: relative;
    line-height: 1;
    top:-0.05em;
}

.site-footer .separator:before {
    content:"/";
}