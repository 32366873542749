* {
  box-sizing: border-box;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: min(calc(1vmin + 50%), 100%);
}

body {
  color: #1d1924;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "SF UI Text", "Helvetica Neue",
    "Roboto", "Arial Nova", "Segoe UI", "Arial", sans-serif;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #191919;
  line-height: 1.25;
  margin: 0;
}

h1 {
  font-size: 2.6em;
  font-weight: 400;
}

h2 {
  font-size: 2.1em;
  font-weight: 400;
}

h3 {
  font-size: 1.8em;
  font-weight: 400;
}

h4 {
  font-size: 1.3em;
  font-weight: 500;
}

h5 {
  font-size: 1.1em;
  font-weight: 600;
}

h6 {
  font-size: 1em;
  font-weight: 600;
}

p {
  margin: 0;
  padding: 0;
}

pre {
  font-family: Monaco, Menlo, Consolas, monospace;
  font-size: 0.9em;
  margin: 0;
  border-radius: 0.2em;
}

code {
  font-family: Monaco, Menlo, Consolas, monospace;
  padding: 0;
  margin: 0;
}

.code-block-filename,
.doc-content > .code-block-filename {
  font-size:0.75em;
  line-height: 1;
  background-color: #202134;
  background-image: linear-gradient(to bottom, #09d 0%, #202134 10%);
  color:#bbbdbf;
  padding:0.4em 0.6em;
  display:table;
  margin-bottom:-1em;
  padding-bottom: 1em;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  font-weight: 300;
  z-index: 1;
  position: relative;
}

pre.highlighted {
  padding: 1rem;
  width:100%;
  overflow: auto;
  /* border-radius:0.2em; */
}

pre.highlighted .line-highlight {
    transform: translateX(calc(-1rem - 3px));
    text-indent: calc(1rem + 3px);
    height: 1.6em;
    width: calc(100% + 2rem + 3px);
    display: block;
    background: rgb(31, 64, 75);
    border-left: 3px solid #09c;
}

h1 code,
h2 code,
h3 code,
h4 code,
h5 code,
h6 code {
  color: #57595a;
}

a,
a code {
  color: inherit;
  text-decoration: none;
}

p a,
span a {
  text-decoration: underline;
}

a:hover {
  color: #09d;
  text-decoration: underline;
}

blockquote {
  margin: 0;
  margin-left: -3px;
  padding: 1em;
  border-left: 3px solid var(--quote-foreground-color, #dbdddf);
  background: var(--quote-background-color, #f6f8fa);
}

blockquote p:first-child {
  margin-top: 0;
}

blockquote p:last-child {
  margin-bottom: 0;
}

blockquote > p:first-child > strong:first-child {
  color: var(--quote-foreground-color);
}

blockquote.note {
  --quote-foreground-color: #1d4ed8;
  --quote-background-color: #eff6ff;
}

blockquote.protip, blockquote.tip {
  --quote-foreground-color: #047857;
  --quote-background-color: #ecfdf5;
}

blockquote.important {
  --quote-foreground-color: #7e22ce;
  --quote-background-color: #faf5ff;
}

blockquote.warning {
  --quote-foreground-color: #b45309;
  --quote-background-color: #fffbeb;
}

blockquote.caution {
  --quote-foreground-color: #b91c1c;
  --quote-background-color: #fef2f2;
}

.anchor {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  text-align: center;
  margin-left: -1.1em;
  padding: 0 0.3em;
  opacity: 0;
  color: #eceef0;
}

.anchor:hover {
  color: #09d;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
  opacity: 1;
}

.anchor .header-link {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

.anchor .header-link:before {
  content: "#";
  font-weight: 600;
}

blockquote code {
  background: #fff;
}

.content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  width: 100%;
}

header,
footer,
section,
nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

header.solid-color {
  padding: 3rem 2rem 4rem;
}
header.solid-color h1 {
  margin-bottom: 0.5rem;
}

/* Tiny Screens */
@media only screen and (max-width: 670px) {
  .gitter-open-chat-button {
    position: static;
    float: right;
  }
}

@media print {
  .gitter-open-chat-button {
    display: none;
  }
}

.unstable-warning {
  background-color: #ffee99;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  padding: 0.5em;
  position: relative;
  z-index: 51;
}